<template>
  <div class="page-wrapper">
      <!--begin::Content-->
        <div class="left"> 
            <div class="logo">
                <a href="#">
                    <img src="/media/logos/logo-dark-new.png" alt="Logo">
                </a>
            </div>
            <div class="reset_head">
              <h1 class="font-size-h1" style="font-weight:bold;color:#000">Forgot Password</h1>
            </div>
            <div class="form">
                <b-form class="form" @submit.stop.prevent="onSubmit">
                     <div role="alert" v-bind:class="{ show: errors.length }" class="alert fade alert-danger d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                        <span>{{ $store.state.forgotPasswordError }}</span>
                    </div>
                    <div role="alert" v-if="success" :class="{ 'show': success }" class="alert fade alert-success d-flex align-items-center pl-3" style="height:36px;margin-bottom:10px;">
                        <span>{{ this.successMsg }}</span>
                    </div>
                    <b-form-group id="example-input-group-1" label="Email" label-for="example-input-1">
                        <b-form-input placeholder="Email" class="form-control form-control-solid h-auto py-5 px-6" id="example-input-1" name="example-input-1" v-model="$v.form.email.$model" :state="validateState('email')"  aria-describedby="input-1-live-feedback"></b-form-input>
                        <div v-if="submitted && $v.form.email.$error" class="invalid-feedback-two">
                             <span v-if="!$v.form.email.required" style="color:red;">Email is required</span>
                             <span v-else-if="!$v.form.email.email" style="color:red;">Email Should be in Valid Format</span>
                        </div>
                    </b-form-group>
                    <!-- <div class="input-group">
                        <label for="">Email</label>
                        <input type="text" placeholder="Type your Email" v-model="$v.form.email.$model">
                        <div v-if="submitted && $v.form.email.$error" class="invalid-feedback-two">
                         <span v-if="!$v.form.email.required" style="color:red;">Email is required</span>
                         <span v-else-if="!$v.form.email.email" style="color:red;">Email Should be in Valid Format</span>
                        </div>
                    </div> -->
                    <div class="skip"> 
                        <button ref="kt_forgot_password_submit" class="btn btn-dark form-control">Save</button>
                    </div>
                    <div class="login_switch">
                        <span>Back To</span>
                        <router-link class="font-weight-bold font-size-3 ml-2" :to="{ name: 'signin' }">
                            Log In
                        </router-link>
                        <!-- <a href="">Log In</a> -->
                    </div>
                </b-form>
            </div>
        </div>
        <div class="right">
              <div class="right-inner">
              <div class="icon1">
                  <img src="media/images/icon 2.png" alt="">
              </div>
              <div class="title">
                  <h2>
                      Connecting <br> leading<br> affiliates to<br> global brands
                  </h2>
              </div>
              <div class="list">
                  <ul>
                      <li><img src="media/images/star 1.png" alt=""> Fast Payments</li>
                      <li><img src="media/images/star 1.png" alt="">Fast Communication</li>
                      <li><img src="media/images/star 1.png" alt="">Transparency</li>
                      <li><img src="media/images/star 1.png" alt="">Higher Payouts</li>
                  </ul>
                  <img src="media/images/icon 2.png" alt="">
              </div>
              <div class="icon2">
                  <img src="media/images/icon 2.png" alt="">
              </div>
              <footer>
                  <p>All rights are reserved</p>
                  <ul>
                      <li>
                          <a href="#">Terms of Service</a>
                      </li>
                      <li>
                          <a href="#">. Privacy Policy</a>
                      </li>
                  </ul>
              </footer>
          </div>
          </div>
      </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/reset_password/reset_password.scss";
</style>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
<script>


import { mapState } from "vuex";
import { FORGOT_PASSWORD } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "forgot_password",
  data() {
    return {
      successMessage : '',
      successMsg : null,
      success :false,
      error : false,
      errorMsg :null,
      submitted :false,
      // Remove this dummy login info
      form: {
        email: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.submitted = true
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      }

      const email = this.$v.form.email.$model;
      // clear existing errors
      // set spinner to submit button
      const submitButton = this.$refs["kt_forgot_password_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      // dummy delay
      // send Forgot Password  request
      this.$store
        .dispatch(FORGOT_PASSWORD, {
          email,
        })
        // go to which page after successfully login
        .then((resp) => {
            this.success = true;
            this.successMsg = resp.message;
            this.resetForm();
            setTimeout(()=>{
                this.$router.push({
                    name: "signin",
                })
            },3000);
        })
        .catch((data) => {
            this.error = true;
            this.errorsMsg = data.message;
        });

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
  },
  mounted() {
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      successs: state => state.auth.successs
    })
  },
};
</script>
<style scoped>
.form-control.form-control-solid {
  background-color: #fff2f2;
  border-color: #f3fcfe;
  border-radius: 5px;
  font-size: 16px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #37a4cb !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #37a4cb !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #37a4cb !important;
}

.sign-up-link {
  color: #000;
  font-weight: bold !important;
}
.head {
  color: black;
  font-weight: 700;
}
.forgot {
  float: right;
  font-weight: 600;
  cursor: pointer;
}

.btn {
  margin-top: 30px;
  border-radius: 5px;
}
.btn-dark {
  background: #000;
  border-color: #000;
}
@font-face {
    font-family:Gilroy ;
    src: url("/fonts/Gilroy-Light.otf");
     
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
}

ul{
    margin: 0;
    padding: 0;
    list-style: none;
}

a{
    text-decoration: none;
}



.page-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    display:flow-root;
    height: 100%;
}


.page-wrapper > div {
    width: 50%;
    padding: 0 50px;
    height: auto;
    float:left;
    min-height: 100vh
}


.right {
    background: linear-gradient(193.56deg, #4A6CA8 5.24%, #193058 71.78%);
    color: #fff;
    padding-bottom: 40px !important;
    padding-top: 50px !important;
    padding-left: 100px !important;
}


.right .title h2 {
    color: #F7FAFC;
    font-style: normal;
    font-weight: bold;
    font-size: 72px;
    line-height: 92px;
}

.right .icon1 {
    margin-bottom: 20px;
}

.right .list {
    margin-top: 70px;
}


.right .list > img {
    padding-right: 80px;
}

.right .list ul li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.005em;
}

.right .list ul li img {
    margin-right: 10px;
}

.right .icon2 {
    margin-top: 50px;
}

.right .icon2 img {
    width: 30px;
    position: relative;
    left: -50px;
}

.right footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.right footer ul {
    display: flex;
}

.right footer ul li a {
    color: #fff;
}

.right footer ul li:first-child {
    margin-right: 30px;
}

.right .list {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.right footer p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #A6B7D4;
}

.right footer ul li a {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #A6B7D4;
}

.right footer p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    /* or 100% */
    display: flex;
    align-items: center;
    /* Theme/Secondary/Dark */
    color: #A6B7D4;
}

.right footer ul li a {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    /* or 100% */
    display: flex;
    align-items: center;
    /* Theme/Secondary/Dark */
    color: #A6B7D4;
}

.left {
    padding: 50px 90px !important;
}

.left .logo {
    margin-bottom: 100px;
}

.left form label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    align-items: center;
    /* Text/Body/Light */
    color: #425466;
    display: inline-block;
    margin-bottom: 5px;
}

.left form input,.left form select {
    width: 100%;
    display: block;
    background: #EDF2F7;
    border-radius: 6px;
    border: 0;
    outline: none;
    color: #7A828A;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
    height: 55px;
    padding: 0 20px;
}
input::placeholder{
        color: #7A828A !important;
}

.left .input-group {
    margin-bottom: 20px;
}

.left form .two-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.left form .two-col .input-group {
    width: 49%;
}


.left form .condition {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.left form .condition input {
    width: auto;
    height: auto;
    position: relative;
    top: -3px;
    margin-right: 10px;
    background: #BCB3E2;
}

.left form .condition label {
    color: #4E5D78;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
}

.left .skip a {
    color: #425466;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 162.02%;
}


.left .skip {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left .skip button {
    background: #193058;
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 14px;
    color: #FFFFFF;
    width: 93px;
    height: 55px;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 100%;  
}
.right-inner {
    max-width: 540px;
}

.login_switch {
    text-align: center;
    margin-top: 20px;
}

.login_switch span {
    display: inline-block;
    margin-right: 0px;
}

.login_switch a {
    color: #193058;
    font-weight: bold !important;
}
.left .input-group {
    position: relative;
}

.left .input-group a {
    position: absolute;
    right: 16px;
    top: 56%;
    cursor: pointer;
}
.reset_head {
    margin-bottom: 95px;
}
@media(max-width:1250px){
    .right .title h2 {
        font-size: 50px;
        line-height: 1.2;
    }
    .right-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .right .list > img {
        padding-right: 0;
    }
    .left {
        padding: 50px 40px !important;
    }
}



@media(max-width:991px){
    .page-wrapper {
        flex-direction: column;
    }
    
    .page-wrapper > div {
        width: 100%;
    }
    .right-inner{
      max-width:100%;
    }
    .list {
    max-width: 540px;
}
}


@media(max-width:767px){
    .left form .two-col .input-group {
        width: 100%;
    }
    
    .left form .two-col {
        flex-direction:column;
    }
    .left {
        margin-bottom: 40px;
    }
    .right {
        padding: 50px 40px !important;
    }
    
    .right .title h2 br {
        display: none;
    }
    
    .right-inner {
        max-width: 100%;
    }
    
    .right .title h2 {
        font-size: 30px;
    }
    
    .right .icon2 img {
        position: static;
        left: 0;
    }
    .left {
        padding: 50px 15px !important;
        height: auto !important;
    }
    .right {
        padding: 50px 15px !important;
        height: auto !important;
    }
    .list {
    max-width: 100%;
}
}


@media(max-width:479px){
    .right footer {
        flex-direction: column;
        align-items: start;
    }
    
    .right footer p {
        margin-bottom: 20px;
    }
    .right footer ul li:first-child {
        margin-right: 15px;
    }
}
</style>
